import React from "react"
import { Link } from "gatsby"
import { motion } from 'framer-motion'
import {StaticImage} from "gatsby-plugin-image"
import Collapsible from 'react-collapsible';
import Layout from "../components/layout"
import scrollTo from 'gatsby-plugin-smoothscroll'
import TestimonialSection from "../components/testimonials-section"
import ArrowRight from "../components/svg/arrowRight"
import Cta from "../components/cta"
import Seo from "../components/seo"
import ChevronDown from "../components/svg/chevronDown"

const WebDesignPage = () => (
    <Layout>
        <Seo title="Web design agency Sydney and Canberra | Sydney web designer" 
            description="Trusted Sydney web design agency. Launch Lab offer web design, product design and web development services to startups and companies throughout Australia."
            pathname="/web-design/"
            serviceType="Web Design"
        />

        <section role="main">
            <div className="container">
                <div className="row flex flex-jc">
                <div className="hero-content sub-hero">
                    <h1
                    className="small-h"
                    ><Link to="/web-design/" className="underline-magic">Web design agency Sydney and Canberra</Link></h1>
                    <motion.h2 
                    className="hero-lead"
                    initial="hidden"
                    animate={{ opacity: [0, 0.25, 0.5, 0.75, 1], y: [80, 0] }} 
                    transition={{ ease: "easeOut", duration: 0.5 }}
                    >
                        We're highly experienced Australian web designers and web developers
                    </motion.h2>
                    <div className="btn-row">
                    <motion.div 
                        initial="hidden"
                        animate={{ opacity: [0, 0.25, 0.5, 0.75, 1], x: [-80, 0] }} 
                        transition={{ ease: "easeOut", duration: 0.5 }}
                    > 
                        <button onClick={() => scrollTo('#anchor')} className="btn btn-lg btn-purple"
                        >
                        <span className="flex flex-ac down">
                            <span className="visually-hidden">Clicking this button will scroll the screen down to the next text section on this page.</span>
                            <ArrowRight />
                        </span>
                        </button>
                    </motion.div>
                    </div>
                </div>
                </div>
            </div>
        </section>

        <section className="bg-purple with-pad-sm">
            <div className="container">
                <div className="row">
                    <div className="below-hero"
                    >
                        <div className="below-hero-widget">
                            <span className="widget-heading">100+</span>
                            <p>We've worked on more than 100 website, app and startup products.</p>
                        </div>
                        <div className="below-hero-widget">
                            <span className="widget-heading">360</span>
                            <p>We offer a 360 degree service from UI design to web and application development.</p>
                        </div>
                        <div className="below-hero-widget">
                            <span className="widget-heading">SYD / CAN</span>
                            <p>Our web design and development work is done onshore in Sydney and Canberra.</p>
                        </div>
                    </div>
                    <div className="work-jumbo-wrapper mt-4 with-radius">
                        <motion.div 
                            className="work-jumbo-wrapper with-radius"
                            initial="hidden"
                            animate={{ opacity: [0, 0.25, 0.5, 0.75, 1], y: [120, 0], scale: [0.9, 1] }} 
                            transition={{ ease: "easeOut", delay: 0.5, duration: 1 }}
                        >
                            <StaticImage 
                                src="../images/launch-lab-work-2024.png"
                                alt="Screengrabs of some of the websites and applications that Launch Lab have designed and developed"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                placeholder="none"
                                backgroundcolor="white"
                                layout="fullWidth"
                                quality="100%"
                                className="with-radius"
                            />
                        </motion.div>
                    </div>
                    <div className="flex flex-jc">
                        <Link to="/contact/" className="btn btn-lg btn-pink">
                        <span className="flex flex-ac">
                                Start your project
                            <ArrowRight />
                        </span>
                        </Link>
                    </div>
                </div>
            </div>
        </section>

        <section className="section-pad" id="anchor">
            <div className="container">
                <div className="row">
                    <div className="cols">
                        <div className="col-26">
                            <h3
                            >Web design to meet business objectives</h3>
                        </div>

                        <div className="col-67 long-text">
                            <p>
                                We focus on minimal clean web design, great user experience and delivering on business objectives.
                            </p>
                            <p>
                                Our web design, and product design, experience spans the following sectors:
                            </p>
                            <ul>
                                <li>Startup websites and UIs:
                                    <ul>
                                        <li>Marketplaces</li>
                                        <li>Healthtech</li>
                                        <li>Proptech</li>
                                        <li>Fintech</li>
                                        <li>HRtech</li>
                                        <li>B2C startups</li>
                                    </ul>
                                </li>
                                <li>Gaming websites</li>
                                <li>Web3 websites</li>
                                <li>Not for profit websites</li>
                                <li>Corporate websites</li>
                            </ul>
                            <p>
                                We also offer a full web development service offering <Link to="/web-development/">custom web development</Link> and <Link to="/webflow-developer/">Webflow websites</Link>.</p>                 
                            <div className="btn-row">
                                <Link to="/contact/" className="btn btn-lg btn-purple">
                                    <span className="flex flex-ac">
                                        Start your project
                                        <ArrowRight />
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div className="container">
            <div className="row">
            <div className="section-line"></div>
            </div>
        </div>

        <section className="section-pad-sm">
            <div className="container">
                <div className="row">   
                    <h4 className="text-center">Recent website projects</h4>
                    <motion.div 
                        className="porti-row mt-3"
                        initial={{ opacity: 0, y: 120 }}
                        whileInView={{ opacity: [0, 0.25, 0.5, 0.75, 1], y: 0, scale: [0.9, 1] }}
                        viewport={{ once: true }}
                        transition={{ ease: "easeOut", delay: "0.25", duration: 0.5 }}
                    >
                        <Link to="/case-study-giraffe-webflow-website/" className="porti-item">
                            <div className="porti-item-img bg-noise giraffe">
                                <StaticImage 
                                    src="../images/giraffe.png"
                                    alt="A collage of the Giraffe website screens"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    placeholder="none"
                                    backgroundcolor="white"
                                    layout="fullWidth"
                                    quality="100%"
                                />
                            </div>
                            <div className="porti-item-text">
                                <h4>Giraffe</h4>
                                <p>We designed and developed a new Webflow website for this Sydney startup.</p>
                                <div className="flex flex-wrap">
                                    <div className="work-pill website">Website</div>
                                    <div className="work-pill webapp">Webflow</div>
                                    <div className="work-pill cms">CMS</div>
                                </div>
                            </div>
                        </Link>

                        <Link to="/game-studio-website/" className="porti-item">
                            <div className="porti-item-img bg-noise apos">
                                <StaticImage 
                                    src="../images/bzt.jpg"
                                    alt="Bazooka Tango homepage screen grab"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    placeholder="none"
                                    backgroundcolor="white"
                                    layout="fullWidth"
                                    quality="100%"
                                />
                            </div>
                            <div className="porti-item-text">
                                <h4>Bazooka Tango</h4>
                                <p>We designed and developed a new website for this US-based game studio.</p>
                                <div className="flex flex-wrap">
                                    <div className="work-pill website">Website</div>
                                    <div className="work-pill webapp">Webflow</div>
                                </div>
                            </div>
                        </Link>
                        
                        <Link to="/webflow-website-case-study/" className="porti-item">
                            <div className="porti-item-img bg-noise immu">
                                <StaticImage 
                                    src="../images/immutable-site.png"
                                    alt="Multiple screens from the Immutable website"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    placeholder="none"
                                    backgroundcolor="white"
                                    layout="fullWidth"
                                    quality="100%"
                                />
                            </div>
                            <div className="porti-item-text">
                                <h4>Immutable</h4>
                                <p>We developed the Webflow Enterprise website for this Sydney scaleup and continue to offer ongoing Webflow support.</p>
                                <div className="flex flex-wrap">
                                    <div className="work-pill website">Website</div>
                                    <div className="work-pill webapp">Webflow</div>
                                    <div className="work-pill cms">CMS</div>
                                </div>
                            </div>
                        </Link>
                        <Link to="/wordpress-to-webflow-case-study/" className="porti-item">
                        <div className="porti-item-img bg-noise apos">
                            <StaticImage 
                                src="../images/apositive.png"
                                alt="A collage of the APositive website screens"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                placeholder="none"
                                backgroundcolor="white"
                                layout="fullWidth"
                                quality="100%"
                            />
                        </div>
                        <div className="porti-item-text">
                            <h4>APositive</h4>
                            <p>We designed and developed a new website on Webflow for this Australian Fintech company.</p>
                            <div className="flex flex-wrap">
                                <div className="work-pill website">Website</div>
                                <div className="work-pill webapp">Webflow</div>
                                <div className="work-pill cms">CMS</div>
                            </div>
                        </div>
                    </Link>
                    </motion.div>
                </div>
                
                <div className="flex flex-jc">
                    <Link to="/portfolio/" className="btn btn-lg btn-purple">
                    <span className="flex flex-ac">
                        View all projects
                        <ArrowRight />
                    </span>
                    </Link>
                </div>
            </div>
        </section>

        <section className="section-pad bg-black">
            <div className="container">
                <div className="row">
                    <div className="cols">
                        <div className="col-26">
                            <h5 className="title">
                                Web Design FAQs
                            </h5>
                        </div>
                        <div className="col-67" itemscope itemtype="https://schema.org/FAQPage">
                            <div itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                                <Collapsible 
                                    transitionTime={100}
                                    trigger={
                                    <>
                                        <div itemprop="name">What is your web design style?</div> <ChevronDown />
                                    </>
                                    }
                                >
                                    <div itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                                    <div itemprop="text">
                                    <p>We design websites with a minimal feel, that load quickly, comply with best practice on-page SEO, work perfectly across devices and place the user first.</p>
                                    </div>
                                    </div>
                                </Collapsible>
                            </div>
                            <div itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                                <Collapsible 
                                    transitionTime={100}
                                    trigger={
                                    <>
                                        <div itemprop="name">What web design software do you use?</div> <ChevronDown />
                                    </>
                                    }
                                >
                                    <div itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                                    <div itemprop="text">
                                    <p>Our preference is to use <a href="https://www.figma.com">Figma</a>. It has become industry standard with UI design professionals and is a powerful tool for designing websites and creating clickable prototypes.</p>
                                    </div>
                                    </div>
                                </Collapsible>
                            </div>
                            <div itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                                <Collapsible 
                                    transitionTime={100}
                                    trigger={
                                    <>
                                        <div itemprop="name">Do you offer web development services?</div> <ChevronDown />
                                    </>
                                    }
                                >
                                    <div itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                                    <div itemprop="text">
                                    <p>Yes. We offer a full stack service and can assist with wireframing, web design, startup design, product design, <Link to="/web-development/">web development</Link> and full application development.</p>
                                    </div>
                                    </div>
                                </Collapsible>
                            </div>
                            <div itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                                <Collapsible 
                                    transitionTime={100}
                                    trigger={
                                    <>
                                        <div itemprop="name">What does a website cost?</div> <ChevronDown />
                                    </>
                                    }
                                >
                                    <div itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                                    <div itemprop="text">
                                    <p>That is a tough question to answer. It depends on your requirements and the size of the project. We are a senior experienced Australian web design and web development team and will always recommend a solution that meets your needs and budget. Why not contact us and we'll discuss your requirements. If we can't assist based on budget we'll tell you straight away.</p>
                                    </div>
                                    </div>
                                </Collapsible>
                            </div>
                            <div itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                                <Collapsible 
                                    transitionTime={100}
                                    trigger={
                                    <>
                                        <div itemprop="name">Do you offer web design services to startups?</div> <ChevronDown />
                                    </>
                                    }
                                >
                                    <div itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                                    <div itemprop="text">
                                    <p>We certainly do. We've worked with over 30 startups across fintech, healthtech, edtech and marketplaces helping them with designing their first website, creating landing pages, designing their products and developing the applications too.</p>
                                    <p>You can see more on our <Link to="/startup-web-designers/">startup web design</Link> page.</p>
                                    </div>
                                    </div>
                                </Collapsible>
                            </div>
                            <div itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                                <Collapsible 
                                    transitionTime={100}
                                    trigger={
                                    <>
                                        <div itemprop="name">Do you only offer web design to businesses in Sydney?</div> <ChevronDown />
                                    </>
                                    }
                                >
                                    <div itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                                    <div itemprop="text">
                                    <p>No. Our agency is based in Sydney and Canberra but we have designed and developed websites for companies and startups from around Australia.</p>
                                    </div>
                                    </div>
                                </Collapsible>
                            </div>
                        </div>
                    </div>
 
                </div>
            </div>
        </section>

        <TestimonialSection />

        <Cta 
            text="Get a web design quote or a product design quote from a local onshore Sydney web design & development team."
        />
    </Layout>
)

export default WebDesignPage